/* Container and Basic Styling */
.terms-wrapper {
  background-color: #f9f9f9;
  padding: 50px;
  max-width: 800px;
  margin: 6% auto;
  border-radius: 15px;
  /* Updated box-shadow for a softer, more subtle effect */
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05), 0 8px 20px rgba(0, 0, 0, 0.03);
}

.terms-title {
  color: #333;
}

.terms-section {
  margin-bottom: 50px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.7s ease-out;
}

/* Fade-In Animations */
.terms-section.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.terms-section.delay-1s {
  transition-delay: 0.1s;
}

.terms-section.delay-2s {
  transition-delay: 0.2s;
}

.terms-section.delay-3s {
  transition-delay: 0.3s;
}

.terms-section.delay-4s {
  transition-delay: 0.4s;
}

.terms-section.delay-5s {
  transition-delay: 0.5s;
}

.terms-section.delay-6s {
  transition-delay: 0.6s;
}

.terms-section.delay-7s {
  transition-delay: 0.7s;
}

.terms-section.delay-8s {
  transition-delay: 0.8s;
}

/* Links */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* General Styling */
p {
  color: #555;
  line-height: 1.7;
}

h2 {
  color: #444;
}
