.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
}

.modal-content {
  width: 90%; /* Occupy 90% of the screen width */
  height: 90%; /* Occupy 90% of the screen height */
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 1001; /* Ensure the close button is on top */
}

.modal-close-icon {
  font-size: 24px;
  color: #333;
  pointer-events: none; /* Allow clicks to pass through to the button */
}

.modal-close:hover {
  background-color: #ff0000; /* Red background on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.modal-close-icon:hover {
  color: white; /* White color for the X on hover */
}
