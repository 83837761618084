/* Base styles for the gallery */
.gallery {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  gap: 5px;
  padding: 5px;
  transition: 0.3s;
  overflow-x: auto; /* Add horizontal scroll if needed */
}

@media (min-width: 768px) {
  .gallery {
    grid-template-rows: 1fr;
  }
}

@media (min-width: 1024px) {
  .gallery {
    grid-template-rows: 1fr;
  }
}

@media (min-width: 1440px) {
  .gallery {
    grid-template-rows: 1fr;
  }
}

.gallery:hover .gallery__image {
  filter: grayscale(1);
}

.gallery__link {
  overflow: hidden;
  height: 400px;
  max-height: 400px;
}

.gallery__link:hover .gallery__image {
  filter: grayscale(0);
}

.gallery__link:hover .gallery__caption {
  opacity: 1;
}

.gallery__thumb {
  position: relative;
  height: 100%;
}

.gallery__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.gallery__image:hover {
  transform: scale(1.1);
}

.gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  font-family: "Helvetica", sans-serif;
  color: white;
  opacity: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}

.caption-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.caption-description {
  font-size: 14px;
  opacity: 0.8;
  color: white;
}
